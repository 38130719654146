/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:c601cc53-afd4-40e3-8616-af6211e0dd3a",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_uULvmAPwb",
    "aws_user_pools_web_client_id": "5e9uh1e75rnpiqj1hgfb7u39q8",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "Auth": {
        "region": "us-east-1",
        "userPoolId": "us-east-1_uULvmAPwb",
        "userPoolWebClientId": "5e9uh1e75rnpiqj1hgfb7u39q8",
        "identityPoolId": "us-east-1:c601cc53-afd4-40e3-8616-af6211e0dd3a",
        "authenticationFlowType": "USER_SRP_AUTH",
        "usernameAttributes": [
            "EMAIL"
        ],
        "signupAttributes": [
            "EMAIL"
        ],
        "mfaConfiguration": "OFF",
        "mfaTypes": [
            "SMS"
        ],
        "passwordProtectionSettings": {
            "passwordPolicyMinLength": 8,
            "passwordPolicyCharacters": []
        },
        "verificationMechanisms": [
            "EMAIL"
        ]
    }
};


export default awsmobile;
