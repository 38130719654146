import React, { useState } from 'react';
import '../stylings/Contact.css';
import dropdownIcon from '../icons/mellowDropDownTwo_ICON.png'; // Import the dropdown icon

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    reason: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Submitted:', formData);
    alert('Thank you for contacting Mellow Heights!');
    setFormData({
      name: '',
      email: '',
      reason: '',
      message: '',
    });
  };

  return (
    <div className="contact-container">
      <h1 className="contact-title">Contact Mellow Heights</h1>
      <p className="contact-subtitle">
        We're excited to hear from you! Whether it’s feedback, a partnership idea, or support—reach out below.
      </p>

      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name" className="form-label">Your Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="form-input"
            placeholder="Enter your name"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="email" className="form-label">Your Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="form-input"
            placeholder="Enter your email"
            required
          />
        </div>

        <div className="form-group select-group">
          <label htmlFor="reason" className="form-label">Reason for Contact</label>
          <div className="dropdown-wrapper">
            <select
              id="reason"
              name="reason"
              value={formData.reason}
              onChange={handleChange}
              className="form-select"
              required
            >
              <option value="" disabled>Select a reason</option>
              <option value="support">Customer Support</option>
              <option value="feedback">Product Feedback</option>
              <option value="collaboration">Collaboration</option>
              <option value="sponsorship">Sponsorship</option>
              <option value="partnership">Business Partnership</option>
              <option value="other">Other</option>
            </select>
            <img src={dropdownIcon} alt="Dropdown Icon" className="dropdown-icon" />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="message" className="form-label">Your Message</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="form-textarea"
            placeholder="Type your message here"
            required
          />
        </div>

        <button type="submit" className="submit-btn">Submit</button>
      </form>
    </div>
  );
};

export default Contact;
