import React, { useContext, useState } from 'react';
import '../stylings/Shop.css';
import { CartContext } from '../context/CartContext';
import Modal from './Modal';

// Updated product image imports
import hydroLidBlack8oz from '../productImgs/hydroLid_BLACK_EGHTOZ.png';
import hydroLidBlack16oz from '../productImgs/HydroLid Black 16oz final.png';
import hydroLidBlue16oz from '../productImgs/HydroLid 16 oz Blue final.png';
import hydroLidGreen8oz from '../productImgs/hydroLid_GREEN_EGHTOZ.png';
import hydroLidGreen16oz from '../productImgs/HydroLid 16oz Green Final.png';
import hydroLidRed8oz from '../productImgs/hydroLid_RED_EGHTOZ.png';
import hydroLidRed16oz from '../productImgs/HydroLid 16oz Red Final.png'; // New Red 16oz product

// Additional 8oz product images
import hydroLidBlue8oz from '../productImgs/HydroLid 8oz + Logo.png';
import hydroLidBlack8ozLogo from '../productImgs/HydroLid 8oz Black + Logo.png';
import hydroLidGreen8ozLogo from '../productImgs/HydroLid 8oz Green + Logo.png';
import hydroLidRed8ozLogo from '../productImgs/RED 8oz.png';

// Standalone lid images
import standaloneBlack from '../productImgs/Hydrolid Blk w LOGO.png';
import standaloneBlue from '../productImgs/Blue lid + logo.png';
import standaloneGreen from '../productImgs/HydroLid Green and Logo.jpg';
import standaloneRed from '../productImgs/HydroLid Red + Logo.png';

// New imports for updated apparel images
import mellowApparelBeanie from '../productImgs/mellowApparel_Beanie_01.png';
import mellowApparelCrewNeck from '../productImgs/mellowApparel_CrewNeck_01.png';

// Exportable products and apparel arrays
export const products = [
  // Updated 8oz HydroLid products with new images
  { id: 1, category: '8oz HydroLid', title: 'Blue 8oz HydroLid', description: 'Classic HydroLid to keep your herbs fresh.', price: 24.99, image: hydroLidBlue8oz },
  { id: 2, category: '8oz HydroLid', title: 'Black 8oz HydroLid', description: 'Black variant to keep your herbs fresh.', price: 24.99, image: hydroLidBlack8ozLogo },
  { id: 3, category: '8oz HydroLid', title: 'Green 8oz HydroLid', description: 'Green variant for freshness.', price: 24.99, image: hydroLidGreen8ozLogo },
  { id: 4, category: '8oz HydroLid', title: 'Red 8oz HydroLid', description: 'Red variant for a vibrant touch.', price: 24.99, image: hydroLidRed8ozLogo },

  // 16oz HydroLid products with new Red 16oz added at the end
  { id: 5, category: '16oz HydroLid', title: 'Blue 16oz HydroLid', description: 'Blue variant for larger quantities.', price: 29.99, image: hydroLidBlue16oz },
  { id: 6, category: '16oz HydroLid', title: 'Black 16oz HydroLid', description: 'Perfect for larger quantities.', price: 29.99, image: hydroLidBlack16oz },
  { id: 7, category: '16oz HydroLid', title: 'Green 16oz HydroLid', description: 'Great for big storage needs.', price: 29.99, image: hydroLidGreen16oz },
  { id: 8, category: '16oz HydroLid', title: 'Red 16oz HydroLid', description: 'Red variant for big storage needs.', price: 29.99, image: hydroLidRed16oz },

  // Standalone HydroLid colors
  { id: 9, category: 'Standalone HydroLid', title: 'Blue HydroLid', description: 'Fits any wide-mouth mason jar.', price: 14.99, image: standaloneBlue, color: 'Blue' },
  { id: 10, category: 'Standalone HydroLid', title: 'Black HydroLid', description: 'Fits any wide-mouth mason jar.', price: 14.99, image: standaloneBlack, color: 'Black' },
  { id: 11, category: 'Standalone HydroLid', title: 'Green HydroLid', description: 'Fits any wide-mouth mason jar.', price: 14.99, image: standaloneGreen, color: 'Green' },
  { id: 12, category: 'Standalone HydroLid', title: 'Red HydroLid', description: 'Fits any wide-mouth mason jar.', price: 14.99, image: standaloneRed, color: 'Red' },

  { id: 13, category: 'Packs', title: '3-Pack Standalone HydroLid', description: 'Save with our pack of 3 standalone lids.', originalPrice: 44.97, price: 39.99, image: standaloneRed },
];

export const apparel = [
  { 
    id: 14, 
    title: 'Mellow Heights Beanie', 
    description: 'Stylish and warm, perfect for all seasons.', 
    price: 19.99, 
    image: mellowApparelBeanie 
  },
  { 
    id: 15, 
    title: 'Mellow Heights Crew Neck', 
    description: 'Cozy and comfortable, ideal for cooler days.', 
    price: 39.99, 
    image: mellowApparelCrewNeck 
  },
];

const Shop = () => {
  const { addToCart } = useContext(CartContext);

  const [quantities, setQuantities] = useState({});
  const [notification, setNotification] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [initialColor, setInitialColor] = useState(null);

  const handleQuantityChange = (id, newQuantity) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [id]: newQuantity > 0 ? newQuantity : 1,
    }));
  };

  const handleAddToCart = (product) => {
    const quantity = quantities[product.id] || 1;
    addToCart({ ...product, quantity, totalPrice: product.price * quantity });
    showCustomNotification(`${quantity} x ${product.title} added to cart!`);
  };

  const showCustomNotification = (message) => {
    setNotification(message);
    setTimeout(() => setNotification(null), 3000);
  };

  const openModal = (product) => {
    setSelectedProduct(product);
    setInitialColor(product.color);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedProduct(null);
    setInitialColor(null);
  };

  const renderProductsByCategory = (category) => {
    return products
      .filter((product) => product.category === category)
      .map((product) => (
        <div key={product.id} className="item-card landscape">
          <div className="item-image-wrapper" onClick={() => openModal(product)}>
            <img src={product.image} alt={product.title} className="item-image" />
          </div>
          <div className="item-content">
            <h3 className="item-title">{product.title}</h3>
            <p className="item-price">${product.price.toFixed(2)}</p>
            {product.originalPrice && (
              <p className="item-original-price">
                <s className="crossed-price">${product.originalPrice.toFixed(2)}</s>
                <span className="item-discount" style={{ color: 'red' }}>Save 11%</span>
              </p>
            )}
            <div className="quantity-add">
              <div className="quantity-selector">
                <label htmlFor={`quantity-${product.id}`} className="quantity-label">Qty:</label>
                <input
                  type="number"
                  id={`quantity-${product.id}`}
                  className="quantity-input apple-style-input"
                  min="1"
                  value={quantities[product.id] || 1}
                  onChange={(e) => handleQuantityChange(product.id, parseInt(e.target.value))}
                />
              </div>
              <button className="add-to-cart-btn" onClick={() => handleAddToCart(product)}>
              Cart
              </button>
            </div>
          </div>
        </div>
      ));
  };

  const scrollToSection = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="shop-container">
     <div className="category-selector-wrapper">
        <div className="category-selector">
          <button onClick={() => scrollToSection('standalone-hydrolid')}>Standalone HydroLid</button>
          <button onClick={() => scrollToSection('8oz-hydrolid')}>8oz HydroLid</button>
          <button onClick={() => scrollToSection('16oz-hydrolid')}>16oz HydroLid</button>
          <button onClick={() => scrollToSection('packs')}>Packs</button>
          <button onClick={() => scrollToSection('apparel')}>Apparel</button>
        </div>
      </div>

      {notification && <div className="custom-notification">{notification}</div>}

      <section id="standalone-hydrolid" className="products-section">
        <h2 className="section-title">Standalone HydroLid</h2>
        <div className="item-grid standalone-grid">{renderProductsByCategory('Standalone HydroLid')}</div>
      </section>

      <section id="8oz-hydrolid" className="products-section">
        <h2 className="section-title">8oz HydroLid</h2>
        <div className="row-of-four">{renderProductsByCategory('8oz HydroLid')}</div>
      </section>

      <section id="16oz-hydrolid" className="products-section">
        <h2 className="section-title">16oz HydroLid</h2>
        <div className="row-of-four-16oz">{renderProductsByCategory('16oz HydroLid')}</div>
      </section>

      <section id="packs" className="products-section">
        <h2 className="section-title">Packs</h2>
        <div className="item-grid">{renderProductsByCategory('Packs')}</div>
      </section>

      <section id="apparel" className="apparel-section">
        <h2 className="section-title">Apparel</h2>
        <div className="item-grid">
          {apparel.map((item) => (
            <div key={item.id} className="item-card landscape">
              <div className="item-image-wrapper" onClick={() => openModal(item)}>
                <img src={item.image} alt={item.title} className="item-image" />
              </div>
              <div className="item-content">
                <h3 className="item-title">{item.title}</h3>
                <p className="item-price">${item.price.toFixed(2)}</p>
                <button className="add-to-cart-btn" onClick={() => handleAddToCart(item)}>
                  Add to Cart
                </button>
              </div>
            </div>
          ))}
        </div>
      </section>

      {isModalOpen && (
        <Modal
          product={selectedProduct}
          initialColor={initialColor}
          onClose={closeModal}
          handleQuantityChange={handleQuantityChange}
          handleAddToCart={handleAddToCart}
        />
      )}
    </div>
  );
};

export default Shop;
