// src/components/Header.jsx
import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import '../stylings/Header.css';
import logo from '../imgs/mellowHeightsLogo_final_Nowords.png';
import cartIcon from '../icons/mellowCart_ICON.png';
import { CartContext } from '../context/CartContext';
import Cart from './Cart';

const Header = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isCartVisible, setIsCartVisible] = useState(false);
  const [scrollToAbout, setScrollToAbout] = useState(false);
  const { cartItems } = useContext(CartContext);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleDropdown = () => {
    setIsDropdownVisible((prev) => !prev);
  };

  const toggleCart = () => {
    setIsCartVisible((prev) => !prev);
  };

  // Calculate total items in the cart
  const totalItems = cartItems.reduce((sum, item) => sum + item.quantity, 0);

  // Scroll to the About section if on the Home page
  useEffect(() => {
    if (scrollToAbout && location.pathname === '/') {
      const aboutSection = document.getElementById('about-section');
      if (aboutSection) {
        aboutSection.scrollIntoView({ behavior: 'smooth' });
        setScrollToAbout(false);
      }
    }
  }, [location.pathname, scrollToAbout]);

  const handleAboutClick = () => {
    if (location.pathname === '/') {
      const aboutSection = document.getElementById('about-section');
      if (aboutSection) {
        aboutSection.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      setScrollToAbout(true);
      navigate('/');
    }
    setIsDropdownVisible(false);
  };

  const handleShopClick = () => {
    navigate('/shop');
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setIsDropdownVisible(false);
  };

  return (
    <>
      <header className="header-container">
        {/* Hamburger Menu */}
        <div
          className={`hamburger-menu ${isDropdownVisible ? 'open' : ''}`}
          onClick={toggleDropdown}
        >
          <div className="bar bar1"></div>
          <div className="bar bar2"></div>
          <div className="bar bar3"></div>
        </div>

        {/* Mellow Text */}
        <div className="header-text header-text-left">Mellow</div>

        {/* Logo */}
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Mellow Heights Logo" className="logo-img" />
          </Link>
        </div>

        {/* Heights Text */}
        <div className="header-text header-text-right">Heights</div>

        {/* Cart Icon with Item Count */}
        <div className="cart-icon" onClick={toggleCart}>
          {totalItems > 0 && (
            <div className="cart-item-count">{totalItems}</div>
          )}
          <img src={cartIcon} alt="Cart Icon" className="cart-img" />
        </div>

        {/* Dropdown Menu */}
        {isDropdownVisible && (
          <div className="dropdown-menu visible">
            <Link to="/" className="dropdown-link" onClick={toggleDropdown}>Home</Link>
            <Link to="/shop" className="dropdown-link" onClick={handleShopClick}>Shop</Link>
            <Link to="/" className="dropdown-link" onClick={handleAboutClick}>About</Link>
            <Link to="/education" className="dropdown-link" onClick={toggleDropdown}>Education</Link> {/* New Education link */}
            {/*<Link to="/blog" className="dropdown-link" onClick={toggleDropdown}>Blog</Link>*/}
            <Link to="/faq" className="dropdown-link" onClick={toggleDropdown}>FAQ</Link>
            <Link to="/contact" className="dropdown-link" onClick={toggleDropdown}>Contact</Link>
          </div>
        )}
      </header>

      {/* Cart Modal */}
      {isCartVisible && <Cart onClose={toggleCart} />}
    </>
  );
};

export default Header;
