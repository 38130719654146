import React from 'react';
import '../stylings/FAQ.css';

const FAQ = () => {
  const faqItems = [
    {
      question: "What is Mellow Heights?",
      answer: "Mellow Heights is a brand dedicated to delivering top-quality, natural wellness products with a focus on a mellow, enjoyable lifestyle. We are passionate about creating innovative solutions that elevate your experience."
    },
    {
      question: "What is the HydroLid?",
      answer: "The HydroLid is our flagship product designed to keep your herbs, spices, and other botanicals fresh and full of flavor. Using advanced humidity control technology, HydroLid ensures optimal moisture levels, extending the life of your products."
    },
    {
      question: "How does payment processing work?",
      answer: "We use Stripe as our secure payment processor, known for industry-leading encryption and safety protocols. There is a 5% processing fee and a 2% sales tax included at checkout for all purchases."
    },
    {
      question: "Do you store any of my personal information?",
      answer: "No, we prioritize your privacy. Mellow Heights does not store or maintain any user data beyond what's provided in our contact forms. Personal data is securely deleted from our systems after 60 days."
    },
    {
      question: "Can I receive exclusive updates or product news?",
      answer: "Yes! Join our community by subscribing to our newsletter. You’ll get updates on new product launches, wellness tips, and exclusive offers. We respect your inbox and will only send valuable, relevant information."
    },
    {
      question: "What is the return policy for Mellow Heights products?",
      answer: "If you're unsatisfied with any purchase, we offer a 30-day return policy. Please reach out to our support team, and we'll be glad to assist you."
    }
  ];

  return (
    <div className="faq-container">
      <h1 className="faq-title">Frequently Asked Questions</h1>
      <p className="faq-intro">Find answers to our most commonly asked questions. If you need further assistance, please reach out through our contact form.</p>
      
      <div className="faq-list">
        {faqItems.map((item, index) => (
          <div key={index} className="faq-item">
            <h2 className="faq-question">{item.question}</h2>
            <p className="faq-answer">{item.answer}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
