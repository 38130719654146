// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Home from './components/Home';
import Shop from './components/Shop';
import Contact from './components/Contact';
import Blog from './components/Blog';
import FAQ from './components/FAQ';
import Header from './components/Header';
import Footer from './components/Footer';
import Cart from './components/Cart';
import Education from './components/Education'; // Import Education component
import { CartProvider } from './context/CartContext';

function App() {
  const [isCartVisible, setIsCartVisible] = useState(false);

  // Function to open the cart modal
  const openCart = () => {
    setIsCartVisible(true);
  };

  // Function to close the cart modal
  const closeCart = () => {
    setIsCartVisible(false);
  };

  return (
    <HelmetProvider>
      <CartProvider>
        <Router>
          <div className="App">
            <Header openCart={openCart} />
            
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/shop" element={<Shop />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/education" element={<Education />} /> {/* New Education route */}
            </Routes>
            
            <Footer />
            {isCartVisible && <Cart onClose={closeCart} />}
          </div>
        </Router>
      </CartProvider>
    </HelmetProvider>
  );
}

export default App;
