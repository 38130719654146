import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../stylings/Footer.css';
import logo from '../imgs/mellowHeightsLogo_final.png';
import mellowXIcon from '../icons/mellowX_ICON.png';
import mellowInstaIcon from '../icons/mellowInsta_ICON.png';

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (path, anchor) => {
    if (path === '/contact') {
      // Navigate to Contact page and scroll to top
      navigate('/contact');
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else if (path !== '/' || anchor === 'top') {
      // Navigate to a new page and scroll to the top
      navigate(path);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else if (location.pathname === '/') {
      // Already on the home page, scroll to the section anchor
      scrollToAnchor(anchor);
    } else {
      // Navigate to the home page, then scroll to the anchor
      navigate(path);
      setTimeout(() => scrollToAnchor(anchor), 100);
    }
  };

  const scrollToAnchor = (anchor) => {
    const element = document.getElementById(anchor);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <footer className="footer-container">
      <div className="footer-content">
        {/* Logo */}
        <div className="footer-logo" onClick={() => handleNavigation('/', 'top')}>
          <img src={logo} alt="Mellow Heights Logo" className="footer-logo-img" />
        </div>

        {/* Links Section */}
        <div className="footer-links">
          <a onClick={() => handleNavigation('/', 'top')} className="footer-link">Home</a>
          <a onClick={() => handleNavigation('/shop', 'top')} className="footer-link">Shop</a>
          <a onClick={() => handleNavigation('/', 'about-section')} className="footer-link">About Us</a>
          <a onClick={() => handleNavigation('/contact', 'top')} className="footer-link">Contact</a>
          <a onClick={() => handleNavigation('/faq', 'top')} className="footer-link">FAQ</a>
        </div>

        {/* Social Media Section */}
        <div className="footer-social">
          <a href="https://x.com" target="_blank" rel="noreferrer" className="social-link">
            <img src={mellowXIcon} alt="Mellow X" className="social-icon" />
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noreferrer" className="social-link">
            <img src={mellowInstaIcon} alt="Mellow Instagram" className="social-icon" />
          </a>
        </div>

        {/* Subscription Section */}
        <div className="footer-subscription">
          <h3 className="subscription-title">Join Our Community</h3>
          <p className="subscription-text">Get updates on new products, tips, and exclusive offers.</p>
          <form className="subscription-form">
            <input 
              type="email" 
              placeholder="Enter your email" 
              className="subscription-input" 
              required 
            />
            <button type="submit" className="subscription-btn">Subscribe</button>
          </form>
        </div>

        {/* Copyright Section */}
        <p className="footer-copyright">© 2024 Mellow Heights. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
