import React, { useState, useEffect } from 'react';
import '../stylings/Blog.css';
import blogPlaceholder from '../imgs/mellowBlogStandIN_TEMP.png'; // Import the stand-in image

// Example blog post data with updated image paths
const blogPosts = [
  {
    id: 1,
    title: 'The Mellow Movement: What It Means',
    image: blogPlaceholder,
    overview: 'Discover the philosophy behind the Mellow Heights lifestyle and how it intersects with well-being, sustainability, and mindful living.',
    content: 'The Mellow Movement is about balancing life’s highs with a sustainable, mindful approach. It embraces well-being and fresh experiences through innovative solutions like HydroLid.',
  },
  {
    id: 2,
    title: 'Staying Fresh: Tips for a Balanced Life',
    image: blogPlaceholder,
    overview: 'Learn the essentials of staying fresh in all aspects of life—mentally, physically, and spiritually.',
    content: 'Staying fresh is more than just a phrase; it’s a lifestyle. From mental clarity to physical well-being, explore tips on how to maintain a fresh outlook on life.',
  },
  {
    id: 3,
    title: 'HydroLid Innovations & More',
    image: blogPlaceholder,
    overview: 'Explore the latest innovations in humidity control and how HydroLid keeps your herbs and ingredients fresh.',
    content: 'HydroLid is an innovative product designed to keep herbs and ingredients fresh by regulating humidity. It’s more than a lid; it’s a lifestyle solution for freshness.',
  },
];

const Blog = () => {
  const [selectedPost, setSelectedPost] = useState(null);

  // Disable body scroll when modal is open
  useEffect(() => {
    if (selectedPost) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [selectedPost]);

  // Open modal with selected post
  const handleReadMore = (post) => {
    setSelectedPost(post);
  };

  // Close modal
  const handleCloseModal = () => {
    setSelectedPost(null);
  };

  return (
    <div className="blog-container">
      <h1 className="blog-title">Mellow Heights Blog</h1>
      <p className="blog-subtitle">Explore insights, stories, and news from Mellow Heights and the community.</p>
      
      <div className="blog-grid">
        {blogPosts.map((post) => (
          <div key={post.id} className="blog-card">
            <img src={post.image} alt={post.title} className="blog-image" />
            <div className="blog-content">
              <h2 className="blog-post-title">{post.title}</h2>
              <p className="blog-overview">{post.overview}</p>
              <button className="read-more-btn" onClick={() => handleReadMore(post)}>
                Read More
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Modal */}
      {selectedPost && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close-btn" onClick={handleCloseModal}>&times;</span>
            <img src={selectedPost.image} alt={selectedPost.title} className="modal-image" />
            <h2 className="modal-title">{selectedPost.title}</h2>
            <div className="modal-text">
              <p>{selectedPost.content}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Blog;
