import React, { useState, useContext, useRef } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import html2canvas from 'html2canvas';
import '../stylings/Checkout.css';
import { CartContext } from '../context/CartContext';
import printerIcon from '../icons/mellowPrinter_ICON.png'; // Import the printer icon

const stripePromise = loadStripe('pk_test_51QDsEsBQZ5LwZWDXsTl7DO6ijMSWmeCSLn3kErx79L7eS80DGgIuATkEbKFo9XbAbvCm87OeXTOw1ITOlVdNbOdu004bLuVB8c');

const Checkout = ({ cartItems, total, onClose }) => {
  const { clearCart } = useContext(CartContext);
  const [customerInfo, setCustomerInfo] = useState({
    name: '',
    email: '',
    address: '',
    city: '',
    zip: '',
    subscribe: false,
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showReceipt, setShowReceipt] = useState(false);
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const receiptRef = useRef(null);

  const stripe = useStripe();
  const elements = useElements();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCustomerInfo({
      ...customerInfo,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const sendEmailReceipt = async (customerEmail, customerName) => {
    try {
      const emailResponse = await fetch('https://cakvx0v4ng.execute-api.us-east-1.amazonaws.com/default/mellowStripePaymentIntent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: customerEmail,
          name: customerName,
          cartItems,
          total,
        }),
      });

      if (!emailResponse.ok) {
        console.error("Failed to send email receipt:", await emailResponse.json());
      } else {
        console.log("Email receipt sent successfully.");
      }
    } catch (error) {
      console.error("Error sending email receipt:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    try {
      const response = await fetch('https://cakvx0v4ng.execute-api.us-east-1.amazonaws.com/default/mellowStripePaymentIntent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          amount: total * 100,
          customerInfo,
          cartItems,
        }),
      });

      const { clientSecret, error } = await response.json();

      if (error) {
        setError(error);
        setLoading(false);
        return;
      }

      const { error: stripeError } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: customerInfo.name,
            email: customerInfo.email,
            address: {
              line1: customerInfo.address,
              city: customerInfo.city,
              postal_code: customerInfo.zip,
            },
          },
        },
      });

      if (stripeError) {
        setError(stripeError.message);
      } else {
        setShowReceipt(true);
        await sendEmailReceipt(customerInfo.email, customerInfo.name);
      }
    } catch (err) {
      setError('Payment failed. Please try again.');
    }

    setLoading(false);
  };

  const handleReceiptClose = () => {
    setShowReceipt(false);
    clearCart();
    onClose();
  };

  const handleImageClick = (imageUrl) => {
    setFullscreenImage(imageUrl);
  };

  const closeFullscreenImage = () => {
    setFullscreenImage(null);
  };

  const handlePrintReceipt = async () => {
    const canvas = await html2canvas(receiptRef.current);
    const dataUrl = canvas.toDataURL('image/png');

    const newWindow = window.open('', '_blank');
    newWindow.document.write(`<img src="${dataUrl}" style="width:100%;height:auto;" />`);
    newWindow.print();
  };

  return (
    <div className="checkout-modal-overlay">
      <div className="checkout-modal">
        <button className="close-btn" onClick={onClose}>
          &times;
        </button>
        <h2 className="checkout-title">Checkout</h2>
        <form className="checkout-form" onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Full Name"
            value={customerInfo.name}
            onChange={handleChange}
            className="checkout-input"
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email Address"
            value={customerInfo.email}
            onChange={handleChange}
            className="checkout-input"
            required
          />
          <input
            type="text"
            name="address"
            placeholder="Address"
            value={customerInfo.address}
            onChange={handleChange}
            className="checkout-input"
            required
          />
          <input
            type="text"
            name="city"
            placeholder="City"
            value={customerInfo.city}
            onChange={handleChange}
            className="checkout-input"
            required
          />
          <input
            type="text"
            name="zip"
            placeholder="ZIP Code"
            value={customerInfo.zip}
            onChange={handleChange}
            className="checkout-input"
            required
          />
          <CardElement className="card-element" />
          {error && <p className="error-message">{error}</p>}

          <div className="checkout-section">
            <h3>Order Summary</h3>
            <ul className="checkout-cart-summary">
              {cartItems.map((item) => (
                <li key={item.id} className="checkout-cart-item">
                  <span className="item-name">{item.title} x {item.quantity}</span>
                  <span className="item-price">${(item.price * item.quantity).toFixed(2)}</span>
                </li>
              ))}
            </ul>
          </div>

          <div className="checkout-total">
            <p>Total: ${total.toFixed(2)}</p>
          </div>
          
          <button type="submit" className="checkout-submit-btn" disabled={!stripe || loading}>
            {loading ? 'Processing...' : 'Pay Now'}
          </button>
        </form>

        {showReceipt && (
          <div className="receipt-modal">
            <div className="receipt-content" ref={receiptRef}>
              <img
                src={printerIcon}
                alt="Print Receipt"
                className="receipt-print-icon"
                onClick={handlePrintReceipt}
              />
              <h2>Thank You for Your Purchase!</h2>
              <p>Your payment was successful.</p>
              <h3>Order Summary</h3>
              <ul className="receipt-cart-summary">
                {cartItems.map((item) => (
                  <li key={item.id} className="receipt-cart-item">
                    <img 
                      src={item.image} 
                      alt={item.title} 
                      className="receipt-item-img" 
                      onClick={() => handleImageClick(item.image)} 
                    />
                    <span className="item-name">{item.title} x {item.quantity}</span>
                    <span className="item-price">${(item.price * item.quantity).toFixed(2)}</span>
                  </li>
                ))}
              </ul>
              <p className="receipt-total">Total Paid: ${total.toFixed(2)}</p>
              <button className="receipt-close-btn" onClick={handleReceiptClose}>
                Close and Return to Shop
              </button>
            </div>
          </div>
        )}


        {fullscreenImage && (
          <div className="fullscreen-image-overlay" onClick={closeFullscreenImage}>
            <img src={fullscreenImage} alt="Enlarged product" />
            <button className="close-fullscreen-btn" onClick={closeFullscreenImage}>&times;</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default function StripeCheckoutWrapper(props) {
  return (
    <Elements stripe={stripePromise}>
      <Checkout {...props} />
    </Elements>
  );
}
