import React, { useContext, useRef, useEffect, useState } from 'react';
import '../stylings/Modal.css';
import { CartContext } from '../context/CartContext';
import { products } from './Shop';

import standaloneBlack from '../productImgs/Hydrolid Blk w LOGO.png';
import standaloneBlue from '../productImgs/Blue lid + logo.png';
import standaloneGreen from '../productImgs/HydroLid Green and Logo.jpg';
import standaloneRed from '../productImgs/HydroLid Red + Logo.png';

const colorOptions = [
  { name: 'Black', colorCode: '#333333', image: standaloneBlack },
  { name: 'Blue', colorCode: '#4a90e2', image: standaloneBlue },
  { name: 'Green', colorCode: '#4caf50', image: standaloneGreen },
  { name: 'Red', colorCode: '#e53935', image: standaloneRed },
];

const Modal = ({ product, onClose, initialColor }) => {
  const { addToCart, showAddToCartNotification } = useContext(CartContext);
  const carouselRef = useRef(null);
  const clonedProducts = [...products, ...products, ...products];
  const [selectedColor, setSelectedColor] = useState(
    colorOptions.find((color) => color.name === initialColor) || colorOptions[0]
  );
  const [quantity, setQuantity] = useState(1);
  const [modalProductDetails, setModalProductDetails] = useState({
    title: product.title,
    description: product.description,
    price: product.price,
    originalPrice: product.originalPrice,
    image: product.image, // Initial image setup
  });

  useEffect(() => {
    const carousel = carouselRef.current;
    if (carousel) {
      const resetScroll = () => {
        if (carousel.scrollLeft <= 0) {
          carousel.scrollLeft = carousel.scrollWidth / 3;
        } else if (carousel.scrollLeft >= carousel.scrollWidth - carousel.clientWidth) {
          carousel.scrollLeft = carousel.scrollWidth / 3;
        }
      };

      carousel.addEventListener('scroll', resetScroll);
      carousel.scrollLeft = carousel.scrollWidth / 3;

      return () => {
        carousel.removeEventListener('scroll', resetScroll);
      };
    }
  }, []);

  useEffect(() => {
    if (initialColor) {
      const color = colorOptions.find((c) => c.name === initialColor);
      if (color) setSelectedColor(color);
    }
  }, [initialColor]);

  const handleColorChange = (color) => {
    setSelectedColor(color);

    // Update product details based on selected color
    const updatedProduct = products.find(
      (p) => p.title.toLowerCase().includes(color.name.toLowerCase()) && p.category === product.category
    );

    if (updatedProduct) {
      setModalProductDetails({
        title: updatedProduct.title,
        description: updatedProduct.description,
        price: updatedProduct.price,
        originalPrice: updatedProduct.originalPrice,
        image: updatedProduct.image, // Update image based on color selection
      });
    }
  };

  const handleAddToCartClick = () => {
    const itemToAdd = {
      ...product,
      title: modalProductDetails.title,
      description: modalProductDetails.description,
      color: selectedColor.name,
      image: modalProductDetails.image, // Set the image in cart
      quantity,
      totalPrice: modalProductDetails.price * quantity,
    };

    addToCart(itemToAdd);
    showAddToCartNotification(itemToAdd);
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>&times;</button>
        <img src={modalProductDetails.image} alt={`${modalProductDetails.title} - ${selectedColor.name}`} className="modal-image" />

        {/* Color Selector */}
        <div className="color-selector">
          <div className="color-options">
            {colorOptions.map((color) => (
              <button
                key={color.name}
                style={{ backgroundColor: color.colorCode }}
                className={`color-option ${selectedColor.name === color.name ? 'active' : ''}`}
                onClick={() => handleColorChange(color)}
              />
            ))}
          </div>
        </div>

        <div className="modal-details">
          <h3 className="modal-title">{modalProductDetails.title}</h3>
          <p className="modal-description">{modalProductDetails.description}</p>

          {modalProductDetails.originalPrice ? (
            <>
              <p className="modal-price-message">Special Offer – Don't miss out!</p>
              <div className="modal-price-section">
                <p className="modal-original-price">${modalProductDetails.originalPrice.toFixed(2)}</p>
                <p className="modal-price">${modalProductDetails.price.toFixed(2)}</p>
                <p className="modal-discount">
                  Save {Math.round(((modalProductDetails.originalPrice - modalProductDetails.price) / modalProductDetails.originalPrice) * 100)}%
                </p>
              </div>
            </>
          ) : (
            <p className="modal-price">${modalProductDetails.price.toFixed(2)}</p>
          )}

          <div className="quantity-add">
            <div className="quantity-selector">
              <label className="quantity-label">Qty:</label>
              <input
                type="number"
                className="quantity-input apple-style-input"
                min="1"
                value={quantity}
                onChange={(e) => setQuantity(parseInt(e.target.value))}
              />
            </div>
            <button className="add-to-cart-btn" onClick={handleAddToCartClick}>
              Add to Cart
            </button>
          </div>
        </div>

        {/* Product Carousel */}
        <div className="carousel-container">
          <h3 className="carousel-title">You May Also Like</h3>
          <div className="carousel" ref={carouselRef}>
            {clonedProducts.map((carouselProduct, index) => (
              <div key={`${carouselProduct.id}-${index}`} className="carousel-item">
                <img src={carouselProduct.image} alt={carouselProduct.title} className="carousel-item-image" />
                <h4 className="carousel-item-title">{carouselProduct.title}</h4>
                <p className="carousel-item-price">${carouselProduct.price.toFixed(2)}</p>
                <button
                  className="carousel-add-btn"
                  onClick={() => {
                    addToCart({
                      ...carouselProduct,
                      quantity: 1,
                      color: selectedColor.name,
                      image: modalProductDetails.image,
                    });
                    showAddToCartNotification(carouselProduct);
                  }}
                >
                  Add to Cart
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
