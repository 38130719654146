import React, { useEffect } from 'react';
import '../stylings/Notification.css';

const Notification = ({ message, visible, onClose }) => {
  useEffect(() => {
    if (visible) {
      const timer = setTimeout(onClose, 3000); // Auto-close after 3 seconds
      return () => clearTimeout(timer);
    }
  }, [visible, onClose]);

  return (
    <div className={`notification ${visible ? 'show' : ''}`}>
      <p className="notification-message">{message}</p>
    </div>
  );
};

export default Notification;
