import React from 'react';
import '../stylings/Education.css';

const Education = () => {
  return (
    <div className="education-container">
      {/* Education Hero Section */}
      <section className="education-hero-section">
        <div className="education-hero-message">
          <p className="education-hero-description">
            Discover the essence of freshness and sustainability with HydroLid — a journey to preserve nature's finest, one jar at a time.
            Dive into the science of humidity control and unlock the secrets to keeping your ingredients vibrant and full of life.
          </p>
        </div>
      </section>

      {/* Blog Posts Section */}
      <section className="blog-section">
        <h2 className="section-title">From the Mellow Heights Blog</h2>
        
        <div className="blog-posts">
          <div className="blog-post">
            <h3 className="blog-post-title">Why Humidity Matters: Keeping Ingredients Fresh</h3>
            <p className="blog-post-excerpt">
              Learn how controlling humidity can make all the difference in preserving flavor and extending the shelf life of your kitchen staples. Discover practical tips to keep your ingredients as fresh as nature intended.
            </p>
            <button className="read-more-btn">Read More</button>
          </div>

          <div className="blog-post">
            <h3 className="blog-post-title">The Essential Guide to Herbal Storage</h3>
            <p className="blog-post-excerpt">
              Herbs are sensitive to light, moisture, and air, making proper storage key to maintaining their quality. Explore our guide on how to store and care for your herbs to maximize their freshness and potency.
            </p>
            <button className="read-more-btn">Read More</button>
          </div>

          <div className="blog-post">
            <h3 className="blog-post-title">Sustainable Living with Mellow Heights</h3>
            <p className="blog-post-excerpt">
              Sustainability is at the heart of everything we do. Discover our approach to eco-friendly living and how you can integrate small, impactful changes into your daily life with Mellow Heights.
            </p>
            <button className="read-more-btn">Read More</button>
          </div>
        </div>
      </section>

      {/* About HydroLid */}
      <section className="product-info-section">
        <h2 className="section-title">What is HydroLid?</h2>
        <p className="section-description">
          HydroLid is a precision-crafted solution designed to keep your ingredients fresh and vibrant. Perfectly fitting any wide-mouth mason jar, HydroLid harnesses the latest in humidity control technology to extend the life and flavor of your herbs, spices, and pantry essentials. Its sleek design makes it an indispensable tool for anyone who values quality and sustainability in their lifestyle.
        </p>
      </section>

      {/* Uses & Benefits */}
      <section className="uses-section">
        <h2 className="section-title">The Power of Versatility</h2>
        <p className="section-description">
          HydroLid’s uses are as diverse as the ingredients it preserves. Ideal for herbal storage, culinary spices, baking essentials, and holistic health products, HydroLid is perfect for preserving the natural aroma, flavor, and texture of your ingredients. Keep them as fresh as the day you stored them.
        </p>
      </section>

      {/* The Mellow Heights Movement */}
      <section className="movement-section">
        <h2 className="section-title">Join the Mellow Heights Movement</h2>
        <p className="section-description">
          At Mellow Heights, we believe in enhancing every day. We champion quality, mindfulness, and sustainability, striving to make a positive impact on your lifestyle. Our mission is to provide you with products that inspire a closer connection with nature, wellness, and a fresh way of living.
        </p>
      </section>
    </div>
  );
};

export default Education;
