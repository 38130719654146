import React, { useContext, useState, useRef, useEffect } from 'react';
import '../stylings/Cart.css';
import { CartContext } from '../context/CartContext';
import Checkout from './Checkout';
import { products } from './Shop';

const Cart = ({ onClose }) => {
  const { cartItems, removeFromCart, updateQuantity, addToCart } = useContext(CartContext);
  const [isCheckoutVisible, setIsCheckoutVisible] = useState(false);
  const carouselRef = useRef(null);
  const clonedProducts = [...products, ...products, ...products]; // Cloned products for infinite loop effect

  const total = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);

  // Aggregate quantity of standalone HydroLid units in the cart regardless of color
  const totalStandaloneLids = cartItems
    .filter(item => item.title.toLowerCase() === 'standalone hydrolid')
    .reduce((sum, item) => sum + item.quantity, 0);

  const standaloneLidPrice = products.find(
    product => product.title.toLowerCase() === 'standalone hydrolid'
  )?.price;

  const threePackHydroLid = products.find(
    product => product.title.toLowerCase() === '3-pack standalone hydrolid'
  );

  // Show savings tip if there are 3 or more standalone lids in the cart, regardless of color
  const showSavingsTip = totalStandaloneLids >= 3;

  useEffect(() => {
    const carousel = carouselRef.current;
    if (carousel) {
      const resetScroll = () => {
        if (carousel.scrollLeft <= 0) {
          carousel.scrollLeft = carousel.scrollWidth / 3;
        } else if (carousel.scrollLeft >= carousel.scrollWidth - carousel.clientWidth) {
          carousel.scrollLeft = carousel.scrollWidth / 3;
        }
      };

      carousel.addEventListener('scroll', resetScroll);

      // Initialize to the middle section
      carousel.scrollLeft = carousel.scrollWidth / 3;

      return () => {
        carousel.removeEventListener('scroll', resetScroll);
      };
    }
  }, []);

  // Increment item quantity
  const handleIncrement = (itemId, color) => {
    const item = cartItems.find((item) => item.id === itemId && item.color === color);
    if (item) {
      updateQuantity(itemId, color, item.quantity + 1);
    }
  };

  // Decrement item quantity
  const handleDecrement = (itemId, color) => {
    const item = cartItems.find((item) => item.id === itemId && item.color === color);
    if (item && item.quantity > 1) {
      updateQuantity(itemId, color, item.quantity - 1);
    }
  };

  // Open checkout modal
  const handleCheckout = () => {
    setIsCheckoutVisible(true);
  };

  // Close checkout modal
  const closeCheckout = () => {
    setIsCheckoutVisible(false);
    onClose();
  };

  return (
    <div className="cart-modal-overlay">
      <div className="cart-modal">
        {/* Close Button */}
        <button className="close-btn" onClick={onClose}>
          &times;
        </button>

        {/* Cart Content */}
        <h2 className="cart-title">Your Cart</h2>

        {/* Scrollable Cart Items Container */}
        <div className="cart-items-container">
          {cartItems.length === 0 ? (
            <p>Your cart is empty</p>
          ) : (
            cartItems.map((item) => (
              <div key={`${item.id}-${item.color}`} className="cart-item">
                <img src={item.image} alt={item.title} className="cart-item-img" />
                <div className="cart-item-details">
                  <h3 className="cart-item-title">{item.title} ({item.color})</h3>
                  <p className="cart-item-price">${item.price.toFixed(2)}</p>
                </div>
                <div className="cart-item-quantity">
                  <button className="quantity-btn" onClick={() => handleDecrement(item.id, item.color)}>
                    -
                  </button>
                  <span className="quantity">{item.quantity}</span>
                  <button className="quantity-btn" onClick={() => handleIncrement(item.id, item.color)}>
                    +
                  </button>
                </div>
                {/* Pass both id and color when calling removeFromCart */}
                <button className="remove-btn" onClick={() => removeFromCart(item.id, item.color)}>
                  Remove
                </button>
              </div>
            ))
          )}
        </div>

        {/* Price Tip */}
        {showSavingsTip && threePackHydroLid && (
          <div className="price-tip">
            <p>Save money by buying our <strong>3-Pack Standalone HydroLid</strong>!</p>
            <p>
              You'll save <strong>${((totalStandaloneLids * standaloneLidPrice) - threePackHydroLid.price).toFixed(2)}</strong> compared to buying 3 standalone lids separately.
            </p>
            <button className="add-three-pack-btn" onClick={() => addToCart(threePackHydroLid)}>
              Add 3-Pack for ${threePackHydroLid.price.toFixed(2)}
            </button>
          </div>
        )}

        {/* Total */}
        <div className="cart-total">
          <p>Total: ${total.toFixed(2)}</p>
        </div>

        {/* Checkout Button */}
        <button className="checkout-btn" onClick={handleCheckout}>
          Checkout
        </button>

        {/* Checkout Modal */}
        {isCheckoutVisible && (
          <Checkout cartItems={cartItems} total={total} onClose={closeCheckout} />
        )}

        {/* Product Carousel */}
        <div className="carousel-container">
          <h3 className="carousel-title">You May Also Like</h3>
          <div className="carousel" ref={carouselRef}>
            {clonedProducts.map((product, index) => (
              <div key={`${product.id}-${index}`} className="carousel-item">
                <img src={product.image} alt={product.title} className="carousel-item-image" />
                <h4 className="carousel-item-title">{product.title}</h4>
                <p className="carousel-item-price">${product.price.toFixed(2)}</p>
                <button
                  className="carousel-add-btn"
                  onClick={() => addToCart({ ...product, quantity: 1 })}
                >
                  Add to Cart
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
